import React, { Component } from 'react';
import Ajax from '../common/ajax';
import Config from '../config';

class VideosMiniList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: {},
      currentVideoSrc: null,
      processing: false,
    };
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    this.fetchVideos();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  fetchVideos() {
    this.setState((state) => ({ ...state, processing: true }));
    Ajax.get(`${Config.apiHost}help/categorized-videos`).done((videos) => {
      this._mounted && this.setState((state) => ({ ...state, videos, processing: false }));
    });
  }

  render() {
    try {
      const categories = Object.keys(this.state.videos);

      if (!categories || !categories.length) {
        return null;
      }

      let videos = this.state.videos[categories[0]];

      if (!videos || !videos.length) {
        return null;
      }

      return videos
        .slice(0, 7)
        .map((video) => (
          <img
            key={video.snippet.thumbnails.high.url}
            className="thumbnail"
            src={video.snippet.thumbnails.high.url}
            width="50px"
            style={{ marginLeft: '15px', display: 'inline', marginBottom: 'unset' }}
          />
        ));
    } catch (error) {
      return <span className="text-danger">Error loading videos.</span>;
    }
  }
}

export default VideosMiniList;
