import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../common/containers/Modal';
import Config from '../../../config';
import Ajax from '../../../common/ajax';
import Button from '../../../common/components/Button';
import Events from '../Events';
import { withNamespaces } from 'react-i18next';
import { AlertError, AlertSuccess } from '../../../common/components/Alert';
import { showDjangoValidationMessages } from '../../../common/validation';

class Abbreviation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      abbreviations: [],
      processing: false,
      showForm: false,
      isEditing: false,
      successSuggestions: null,
      form: {
        id: '',
        name: '',
        text: '',
      },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showForm = this.showForm.bind(this);
    this.save = this.save.bind(this);
    this.fetchAbbreviations = this.fetchAbbreviations.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fetchSuggestions = this.fetchSuggestions.bind(this);
  }

  openModal() {
    this.setState((state) => ({
      ...state,
      showModal: true,
      successSuggestions: null,
    }));

    this.fetchAbbreviations();
  }

  closeModal() {
    this.setState((state) => ({
      ...state,
      showModal: false,
    }));
  }

  fetchAbbreviations() {
    this.setState((state) => ({ ...state, processing: true }));
    const url = `${Config.apiHost}abbreviations/?document=${this.props.document.id}`;
    Ajax.get(url)
      .done((abbreviations) => {
        this.setState((state) => ({ ...state, abbreviations }));
      })
      .always(() => {
        this.setState((state) => ({
          ...state,
          processing: false,
          showForm: false,
          isEditing: false,
          form: {},
        }));
      });
  }

  fetchSuggestions() {
    const { i18n } = this.props;
    this.setState((state) => ({
      ...state,
      processing: true,
    }));
    const url = `${Config.apiHost}documents/${this.props.document.id}/find_new_abbreviations/`;
    Ajax.get(url)
      .done((data) => {
        const count = data.count;
        this.setState((state) => ({
          ...state,
          successSuggestions: `${i18n.t('Total de novas siglas encontradas')}: ${count}`,
        }));
        this.fetchAbbreviations();
      })
      .always(() => {
        this.setState((state) => ({
          ...state,
          processing: false,
        }));
      });
  }

  showForm(show) {
    this.setState((state) => ({
      ...state,
      showForm: show,
      isEditing: show,
      successSuggestions: null,
      form: {
        id: '',
        name: '',
        text: '',
      },
    }));
  }

  onChange(e) {
    e.persist();
    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        [e.target.name]: e.target.value,
      },
    }));
  }

  save() {
    const data = {
      name: this.state.form.name,
      text: this.state.form.text,
      document: this.props.document.id,
    };
    if (this.state.form.id) {
      const id = this.state.form.id;
      const url = `${Config.apiHost}abbreviations/${id}/`;
      Ajax.put(url, data)
        .done(() => {
          this.fetchAbbreviations();
          this.props.editor.fire(Events.COMPILE);
        })
        .fail((_jqXHR) => {
          showDjangoValidationMessages(_jqXHR.responseJSON);
        });
    } else {
      const url = `${Config.apiHost}abbreviations/`;
      Ajax.post(url, data)
        .done(() => {
          this.fetchAbbreviations();
          this.props.editor.fire(Events.COMPILE);
        })
        .fail((_jqXHR) => {
          showDjangoValidationMessages(_jqXHR.responseJSON);
        });
    }
  }

  edit(abbreviation) {
    this.setState((state) => {
      return {
        ...state,
        showForm: true,
        isEditing: true,
        form: {
          id: abbreviation.id,
          name: abbreviation.name,
          text: abbreviation.text,
        },
      };
    });
  }

  remove(abbreviation) {
    const { i18n } = this.props;
    const msg = `${i18n.t('Deseja realmente remover a sigla')} "${abbreviation.name}"?`;
    if (confirm(msg)) {
      const url = `${Config.apiHost}abbreviations/${abbreviation.id}/`;
      Ajax.delete_(url).done((response) => {
        this.fetchAbbreviations();
        this.props.editor.fire(Events.COMPILE);
      });
    }
  }

  renderAbbreviations() {
    if (!this.state.abbreviations.length) {
      return null;
    }

    const { i18n } = this.props;

    return this.state.abbreviations.map((abbreviation) => {
      return (
        <tr key={abbreviation.id}>
          <td>{abbreviation.name}</td>
          <td>{abbreviation.text}</td>

          <td>
            <a role="button" className="btn btn-link btn-lg" onClick={() => this.edit(abbreviation)}>
              <i className="icon mdi mdi-edit" />
            </a>
          </td>
          <td>
            <a role="button" className="btn btn-link btn-lg" onClick={() => this.remove(abbreviation)}>
              <i className="icon mdi mdi-delete" />
            </a>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { i18n } = this.props;
    return (
      <React.Fragment>
        <a role="button" onClick={this.openModal} title={i18n.t('Editar siglas do documento')}>
          <span className="mdi mdi-view-list" />
          &nbsp;
          <span>{i18n.t('Siglas')}</span>
        </a>

        <Modal
          title={i18n.t('Siglas')}
          show={this.state.showModal}
          width="large"
          isProcessing={this.state.processing}
          onCancel={this.closeModal}
        >
          <AlertSuccess>{this.state.successSuggestions}</AlertSuccess>

          {!this.state.showForm && (
            <Button type="primary" size="md" onClick={() => this.showForm(true)} className="pull-right">
              {i18n.t('Novo')}
            </Button>
          )}
          <a className="btn btn-link pull-right" onClick={this.fetchSuggestions}>
            <i className="fas fa-search" /> Encontrar siglas no documento
          </a>

          <table className="table table-hover">
            <thead>
              <tr>
                <th>{i18n.t('Sigla')}</th>
                <th>{i18n.t('Descrição')}</th>
                <th colSpan={2} width="1%" nowrap="nowrap" />
              </tr>
            </thead>
            <tbody>
              {this.state.showForm && (
                <tr>
                  <td>
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        onChange={this.onChange}
                        value={this.state.form.name}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="text"
                        name="text"
                        className="form-control"
                        onChange={this.onChange}
                        value={this.state.form.text}
                      />
                    </div>
                  </td>
                  <td colSpan={2} nowrap="nowrap" />
                </tr>
              )}

              {!this.state.isEditing && this.renderAbbreviations()}
            </tbody>
          </table>

          {this.state.showForm && (
            <React.Fragment>
              <Button type="primary" size="lg" onClick={this.save}>
                {i18n.t('Salvar')}
              </Button>
              &nbsp;
              <Button type="default" size="lg" onClick={() => this.showForm(false)}>
                {i18n.t('Cancelar')}
              </Button>
            </React.Fragment>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

Abbreviation.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(Abbreviation);
