import React from 'react';

export default function PlanFeature({ name, title, enabled, children }) {
  return (
    <li
      title={title}
      style={{
        textDecoration: enabled ? '' : 'line-through',
        fontWeight: 400,
        opacity: enabled ? '100%' : '40%',
      }}
    >
      {enabled ? (
        <i className="text-success fas fa-check">&nbsp;</i>
      ) : (
        <i className="text-danger fas fa-close">&nbsp;</i>
      )}
      {name}
      {children}
    </li>
  );
}
